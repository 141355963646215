import Navbar from "../components/Navbar";
import Footer from "../components/Footer2";
import MapWithRoute from "../components/MapWithRoute ";
import "./Home.css";
function MapHpiz() {
    const startPoint = [32.458332614444224, 14.560659870796098]; // نيويورك
    const endPoint = [32.47802100609659, 14.578191404972]; // لوس أنجلوس
    return (
      <>
        <Navbar/>
        <div className="map-wrapper" style={{ marginTop: "120px" }}>

              <MapWithRoute start={startPoint} end={endPoint} />
          </div>
        <Footer/>
      </>
    );
  }
  
  export default MapHpiz;
  