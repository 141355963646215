import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine";
import L from "leaflet";
import "./MapStyle.css";
// إصلاح مشكلة الأيقونات الافتراضية في Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.9.3/dist/images/marker-shadow.png",
});

// مكون فرعي لإضافة المسار
const RoutingControl = ({ start, end, startLabel, endLabel }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // إضافة المسار باستخدام leaflet-routing-machine
    const routingControl = L.Routing.control({
      waypoints: [
        L.latLng(start[0], start[1]),
        L.latLng(end[0], end[1]),
      ],
      routeWhileDragging: true,
      lineOptions: {
        styles: [{ color: "blue", weight: 4 }],
      },
      createMarker: (i, waypoint) => {
        const label = i === 0 ? startLabel : endLabel;
        return L.marker(waypoint.latLng).bindPopup(`<div class="custom-popup">${label}</div>`);
      },
    }).addTo(map);

    // تقريب الخريطة للنقاط
    map.fitBounds(L.latLngBounds([start, end]));

    return () => {
      map.removeControl(routingControl);
    };
  }, [map, start, end, startLabel, endLabel]);

  return null;
};

const MapWithRoute = () => {
  const startPoint = [32.458332614444224, 14.560659870796098]; // نقطة البداية (جسر زليتن)
  const endPoint = [32.47802100609659, 14.578191404972]; // نقطة النهاية (المعهد العالي)
  const startLabel = "جسر زليتن";
  const endLabel = "المعهد العالي";

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <MapContainer
        center={startPoint}
        zoom={10}
        style={{ height: "100%", width: "100%" }}
      >
        {/* طبقة الخريطة */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* مكون المسار */}
        <RoutingControl
          start={startPoint}
          end={endPoint}
          startLabel={startLabel}
          endLabel={endLabel}
        />
      </MapContainer>
    </div>
  );
};

export default MapWithRoute;
